<template>
  <Card title="修改考核计划">
    <div style="padding-top: 16px"></div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="计划名称">
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'name',
                                    {
                  initialValue: text.name,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="考核时间">
            <a-range-picker
              style="width: 100%"
              :placeholder="['起始年月','结束年月']"
              v-decorator="[
                              'time',
                     
                                                                 {
                  initialValue: text.time,
                  rules: [{ required: true, message: '请输入' }],
                },
                            ]"
              format="YYYY-MM-DD"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="考核人员">
            <a-select
              style="width: 100%"
              mode="multiple"
              v-decorator="[
                              'users',
                                             {

                                              initialValue: text.users,
                  rules: [{ required: true, message: '请选择' }],
                },
                            ]"
            >
              <a-select-option v-for="item in userList" :key="item.id" :value="item.id">
                {{
                item.name
                }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="备注">
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'remark',
                {
                  initialValue: text.remark,
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </Card>
</template>

<script>
import { fetchList } from "@/api/setting/user";
import { edit } from "@/api/personnel-assess";
import moment from "moment";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      userList: [],
      text: {},
      saveLoading: false,
    };
  },

  mounted() {
    fetchList({
      pageNum: 1,
      pageSize: 999,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.userList = res.list;
      }
    });

    const text = this.$route.query;
    if (!text.id) return;

    const userList = text.userList || [];

    this.text = {
      ...text,
      time: [moment(text.timeBegin), moment(text.timeEnd)],
      users: userList.map((item) => item.userId),
    };
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let timeBegin, timeEnd;
          if (Array.isArray(values.time) && values.time.length === 2) {
            timeBegin = values.time[0].format("YYYY-MM-DD");
            timeEnd = values.time[1].format("YYYY-MM-DD");
          }

          const userList = [];
          values.users.forEach((id) => {
            const obj = this.userList.find((item) => item.id === id);
            if (obj) {
              userList.push({
                userId: id,
                userName: obj.name,
              });
            }
          });

          this.saveLoading = true;

          edit({
            id: this.text.id,
            ...values,
            timeBegin,
            timeEnd,
            time: undefined,
            userList,
            users: undefined,
          })
            .then(() => {
              this.$router.go(-1);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  padding-bottom: 24px;
}
</style>
